import Base from "./base";
import crypto from "crypto";
import Storage from "../assets/js/storage.js";
import qs from "qs";
import Vue from "vue";
var vm = new Vue();

// 首页
class Index extends Base {
    advertisement(data) {
            return this.post("/api/a/getForPc", data);
        } // 广告及轮播
    recommandSpu(data) {
            return this.post("/api/goods_spu/list", data);
        } // 推荐商品

    recommandCategory(data) {
            return this.post("/api/index/recommend", data);
        } // 推荐分类

    // 商户信息
    shopInfo() {
        return this.get("/api/shop/get");
    }

    getCate(data) {
            return this.post("/api/category/list", data);
        } // 获取商品分类
    search(data) {
            return this.post("/api/goods_spu/search", getSign(data));
        } // 获取商品分类
    pics(data) {
            return this.post("/api/ad/get", getSign(data));
        } // 获取首页图片
    changeLang(data) {
            return this.post("/api/main/langSet", data);
        }
        // 标签
    label(data) {
        return this.post("/api/label/index", data);
    }

}
// 商品
class Goods extends Base {
    list(data) {
        return this.post("/api/goods_spu/list", getSign(data));
    }
    recommand(data) {
            return this.post("/api/goods_spu/associateSpu", data);
        } // 推荐商品
    search(data) {
        return this.post("/api/goods_spu/search", getSign(data));
    }
    detail(data) {
        return this.post("/api/goods_spu/get", data);
    }
    band(data) {
        return this.post("api/label/index", getSign(data));
    }
}
// 地址
class Address extends Base {
    areaList(data) {
        return this.post("/api/area/list", getSign(data));
    }
    list(data) {
        return this.post("/api/address/list", getSign(data));
    }
    del(data) {
        return this.post("/api/address/delete", getSign(data));
    }
    edit(data) {
        return this.post("/api/address/update", getSign(data));
    }
}
// 注册登录
class Login extends Base {
    sendCode(data) {
            return this.post("/api/main/getPhoneCode", data);
        } //发送验证码
    login(data) {
        return this.post("/api/main/loginByEmail", data);
    }
    regist(data) {
        return this.post("/api/main/registerByEmail", data);
    }
    changePwd(data) {
        return this.post("/api/user/changePassword", getSign(data));
    }
}
// 购物车
class Cart extends Base {
    add(data) {
            return this.post("/api/cart/add", getSign(data));
        } //发送验证码
    list(data) {
        return this.post("/api/cart/list", getSign(data));
    }
    update(data) {
        return this.post("/api/cart/renew", getSign(data));
    }
    del(data) {
        return this.post("/api/cart/del", getSign(data));
    }
}
// 收藏
class Like extends Base {
    add(data) {
            return this.post("/api/i_spu_collect/add", getSign(data));
        } //发送验证码
    list(data) {
        return this.post("/api/i_spu_collect/list", getSign(data));
    }
    del(data) {
        return this.post("/api/i_spu_collect/del", getSign(data));
    }
}
//订单
class Order extends Base {
    list(data) {
        return this.post("/api/order/list", getSign(data));
    }
    detail(data) {
        return this.post("/api/order/get", getSign(data));
    }
    ensure(data) {
        return this.post("/api/order/prepare", getSign(data));
    }
    create(data) {
        return this.post("/api/order/create", getSign(data));
    }
    readyToPay(data) {
        return this.post("/api/order/readyToPay", getSign(data));
    }
    cancel(data) {
        return this.post("/api/order/cancel", getSign(data));
    }
}
// 关于我们
class AboutUs extends Base {
    connect(data) {
        return this.post("/api/feedback/add", getSign(data));
    }
    article(data) {
        return this.post("/api/article/list", getSign(data));
    }
    articleDetail(data) {
        return this.post("/api/article/get", getSign(data));
    }
}

export default {
    Index: new Index(),
    Goods: new Goods(),
    Login: new Login(),
    Cart: new Cart(),
    Order: new Order(),
    Like: new Like(),
    Address: new Address(),
    AboutUs: new AboutUs(),
};

function getSign(data) {
    for (let key in data) {
        if (!data[key] && data[key] !== 0) {
            delete data[key];
        }
    }

    let newkey = Object.keys(data).sort();
    let newObj = {};
    for (let i = 0; i < newkey.length; i++) {
        newObj[newkey[i]] = data[newkey[i]];
    }
    let o = {};
    var userInfo = Storage.get("user_info");
    try {
        o.token = userInfo.token;
        let time = new Date().getTime() + "";
        time = time.substring(0, 10);
        o.time = time;
        o.sign = toStr(newObj) + userInfo.secretKey + time;
        o.sign = toMD5(o.sign);
    } catch {}

    for (let key in data) {
        o[key] = data[key];
    }
    return o;
}

function toMD5(str) {
    var md5 = crypto.createHash("md5");
    md5.update(str);
    return md5.digest("hex");
}

function toStr(obj) {
    let str = "";
    for (let key in obj) {
        if (!str) {
            str += key + "=" + obj[key];
        } else {
            str += "&" + key + "=" + obj[key];
        }
    }
    return str;
}