<template>
  <div id="app">
    <router-view v-if="done" />
    <div v-else class="loadings"><img :src="$store.state.shopInfo.logo" /></div>
    <!-- <el-dialog class="profile" :visible.sync="cookie" width="370px" top="30vh" :show-close="false"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<div class="logo">
				<img src="./assets/icon/logo.png" width="200px" alt="" />
			</div>
			<div class="text">
				Utilizamos cookies propias y de terceros para elaborar información estadística y
				mostrarte publicidad personalizada a través del análisis de tu navegación
			</div>
			<div class="ok" @click="cookieOk">OK</div>
		</el-dialog> -->
  </div>
</template>
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
import Api from "./api/request.js";
export default {
  name: "APP",
  data() {
    return {
      done: true, //暂时屏蔽loading
      cookie: false,
    };
  },
  beforeCreate() {
    // 判断是否为移动端
    this.$store.state.platform = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      navigator.userAgent
    )
      ? "mobile"
      : "pc";
    if (this.$storage.get("user_info")) {
      this.$store.state.isLogin = true;
    }
    this.$bus.$on("quit", (res) => {
      this.quitUser();
    });
    this.$bus.$on("addCart", (res) => {
      this.addToCart(res);
    });
    this.$bus.$on("addLike", (res) => {
      this.addToLike(res);
    });
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  watch: {
    language(res) {
      this.getLang(res);
    },
  },
  created() {
    this.getGlobalOptions();
  },
  mounted() {
    this.getLang(this.$store.state.language);

    Api.Index.shopInfo().then((resp) => {
      const data = resp.data.data || {};
      this.changeFavicon(data.icon);
      document.title = data.shopName || "";
      this.$store.state.shopInfo = data || {};
    });
  },

  methods: {
    changeFavicon(src) {
      if (!src) return;
      // document.head = document.head || document.getElementsByTagName("head")[0];
      var link = document.createElement("link"),
        oldLink = document.getElementById("dynamic-favicon");
      link.id = "dynamic-favicon";
      link.rel = "shortcut icon";
      link.href = src;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    },
    getLang(res) {
      let lang_type = "";
      res == "es-ES"
        ? (lang_type = "spanish")
        : res == "en-GB"
        ? (lang_type = "english")
        : res == "zh-CN"
        ? (lang_type = "chinese")
        : "";
      return;
      Api.Index.changeLang({
        lang_type,
      });
    },
    getGlobalOptions() {
      //获取全局数据
      Promise.all([
        new Promise((resolve, reject) => {
          Api.Address.areaList({
            parent_id: 0,
          }).then((res) => {
            // console.log(res.data.data)
            // this.$store.state.areaList = res.data.data.filter(item => {
            // 	return item.areaId == '215';
            // });
            this.$store.state.areaList = res.data.data;
            resolve();
          });
        }),
        new Promise((resolve, reject) => {
          Api.Index.getCate().then((res) => {
            this.$store.state.cateList = res.data.data.slice(0, 6);
            var checked = [];
            const fn = (source) => {
              source.forEach((el) => {
                checked.push(el);
                el.sons && el.sons.length > 0 ? fn(el.sons) : ""; // 子级递归
              });
            };
            fn(res.data.data);
            this.$store.state.cateArr = checked;
            resolve();
          });
        }),
      ]).then((res) => {
        this.done = true;
        if (!sessionStorage.getItem("cookie")) {
          this.cookie = true;
        }
      });
    },
    // 全局都会用到的一些方法
    quitUser() {
      //退出登录
      // this.$dialog.confirm(this.$t('login.logoutTitle') + '?',111,{
      // 	showCancelButton: false,
      //       confirmButtonText: this.$t("login.created"),
      //       confirmButtonClass: "confirm_btn",
      // }).then(res => {
      // 	this.$storage.remove('user_info');
      // 	this.$store.state.isLogin = false;
      // 	try {
      // 		this.$router.replace('/Home');
      // 	} catch {}
      // });
      this.$confirm(
        this.$t("login.logoutTitle") + "?",
        this.$t("login.titles"),
        {
          confirmButtonText: this.$t("login.submit"),
          cancelButtonText: this.$t("login.cancel"),
        }
      ).then((res) => {
        this.$storage.remove("user_info");
        this.$bus.$emit("getCartNum");

        this.$store.state.isLogin = false;
        try {
          this.$router.replace("/Home");
        } catch {}
      });
    },
    addToCart(id) {
      //添加购物车
      const h = this.$createElement;
      this.$dialog.loading();
      Api.Cart.add({
        skuId: id,
        num: 1,
      }).then((res) => {
        this.$dialog.close();
        if (res.data.status == "SUCCESSS") {
          this.$bus.$emit("getCartNum");
          this.$notify({
            customClass: "cartDialog",
            dangerouslyUseHTMLString: true,
            message: h(
              "div",
              {
                class: "cartDia",
              },
              [
                h("i", {
                  class: "el-icon-shopping-bag-2",
                }),
                h(
                  "div",
                  {
                    class: "tip",
                  },
                  this.$t("tip.suc_cart")
                ),
                h(
                  "div",
                  {
                    class: "button",
                    on: {
                      click: this.goCart,
                    },
                  },
                  this.$t("tip.check_cart")
                ),
              ]
            ),
            onClick() {
              this.close();
            },
          });
        }
      });
    },
    addToLike(data) {
      //添加收藏
      const h = this.$createElement;
      this.$dialog.loading();
      if (data.isCollect == 0) {
        Api.Like.add({
          spu_id: data.spuId,
        }).then((res) => {
          this.$dialog.close();
          if (res.data.status == "SUCCESSS") {
            this.$notify({
              customClass: "cartDialog",
              dangerouslyUseHTMLString: true,
              message: h(
                "div",
                {
                  class: "cartDia",
                },
                [
                  h("i", {
                    class: "el-icon-star-off",
                  }),
                  h(
                    "div",
                    {
                      class: "tip",
                    },
                    this.$t("tip.suc_like")
                  ),
                  h(
                    "div",
                    {
                      class: "button",
                      on: {
                        click: this.goLike,
                      },
                    },
                    this.$t("tip.check_like")
                  ),
                ]
              ),
              onClick() {
                this.close();
              },
            });
          }
        });
      } else {
		Api.Like.del({
           collect_id: data.collectId,
        }).then((res) => {
          this.$dialog.close();
          if (res.data.status == "SUCCESSS") {
            this.$notify({
              customClass: "cartDialog",
              dangerouslyUseHTMLString: true,
              message: h(
                "div",
                {
                  class: "cartDia",
                },
                [
                  h("i", {
                    class: "el-icon-star-off",
                  }),
                  h(
                    "div",
                    {
                      class: "tip",
                    },
                    this.$t("tip.suc_like")
                  ),
                  h(
                    "div",
                    {
                      class: "button",
                      on: {
                        click: this.goLike,
                      },
                    },
                    this.$t("tip.check_like")
                  ),
                ]
              ),
              onClick() {
                this.close();
              },
            });
          }
        });
      }
    },
    goCart() {
      this.$router.push("/Cart");
    },
    goLike() {
      this.$router.push("/Collect");
    },
    cookieOk() {
      this.cookie = false;
      sessionStorage.setItem("cookie", true);
    },
  },
};
</script>
<style lang="less">
@import "./assets/css/global.css";
#app {
  // height: 100%;
  // overflow: scroll;
}
.loadings {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 25vw;
  }
}

.profile {
  .text {
    font-size: 18px;
    text-indent: 2em;
    margin: 30px auto 50px;
  }

  .ok {
    width: 320px;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    background: #123178;
    cursor: pointer;
  }

  .logo {
    text-align: center;
  }

  .el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      display: none;
    }
  }
}
</style>
